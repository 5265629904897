import '../../Styles/nosServices.scss';
import sites from '../../Assets/icons/sites.png';
import infography from '../../Assets/icons/infography.png';
import social from '../../Assets/icons/social.png';
import redaction from '../../Assets/icons/redaction.png';
import ServiceCard from '../../Components/ServiceCard/serviceCard';
import { HashLink } from 'react-router-hash-link';


export default function Services() {
    return (
        <div className="nosServices">
            <h2>NOS <span>SERVICES</span></h2>

            <div className="cardServices">
                <HashLink to="/services#sites" className="card">
                    <ServiceCard icon={sites} title="Sites web sur mesure">
                        <div className="p">
                            <p>Nous proposons des sites conçus spécialement pour vous.</p>

                            <p>Chaque détail est pensé selon vos besoins et votre identité.</p>

                            <p>Un site internet est votre carte de visite digitale, il est le reflet de votre image de marque et de votre savoir-faire. <br/>
                                Il est l’outil indispensable pour présenter votre offre et guider vos visiteurs.</p>
                        </div>
                    </ServiceCard>

                </HashLink >

                <HashLink to="/services#infograf" className="card">
                    <ServiceCard icon={infography} title="Infographie Print et Digitale">
                        <div className="p">
                            <p>Nous mettons en avant l’image de marque de votre société.</p>

                            <p>De la création de votre logo à la réalisation de tous supports de communication (flyers, carte de visite, signalétique, enveloppes personnalisées ...) nous mettons tout en oeuvre pour faire de vos visuels l’atout majeur de votre communication.</p>

                        </div>
                    </ServiceCard>
                </HashLink>

                <HashLink to="/services#social" className="card">
                    <ServiceCard icon={social} title="Réseaux sociaux">
                        <div className="p">
                            <p>Nous gérons pour vous vos réseaux sociaux par le biais de publications personnalisées et régulières.</p>

                            <p>Aujourd’hui la présence sur les réseaux sociaux est devenue indispensable, et une bonne communication sur ces canaux est un atout essentiel de votre image de marque.</p>

                            <p>Une bonne visibilité sur ces plateformes est la garantie d’une publicité efficace.</p>
                        </div>
                    </ServiceCard>
                </HashLink>

                <HashLink to="/services#redac" className="card">
                    <ServiceCard icon={redaction} title="Rédaction">
                        <div className="p">
                            <p>Que ce soit pour l’envoi régulier de newsletters, pour la création d’articles ou pour la rédaction de vos courriers, nous vous proposons une rédaction de qualité.</p>

                            <p>Personnalisé et adapté à vos besoins, notre service de rédaction saura vous apporter la solution idéale à votre besoin de communication.</p>
                        </div>
                    </ServiceCard>
                </HashLink>
            </div>

        </div>
    )
}