import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header/header';
import { createGlobalStyle } from 'styled-components';
import Home from './Pages/Home/index';
import Services from './Pages/Services/index';
import Realisations from './Pages/Realisations/index';
import Contact from './Pages/Contact/index.js';
import Footer from './Components/Footer/footer';
import Rea from "./Pages/Realisation";
// require('dotenv').config();


/**
 *  ajout des polices
 *
 * @type {Root}
 */
const FontStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Radley:ital@0;1&family=RocknRoll+One&display=swap');
`;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <FontStyles />
      <Router>
          <Header />
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/realisations" element={<Realisations />} />
              <Route path="/contact" element={<Contact />} />
              <Route path = "/realisation" element ={<Rea />} />
              </Routes>
          <Footer />


      </Router>
  </React.StrictMode>
);
