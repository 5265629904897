import '../../Styles/footer.scss';
import logo from '../../Assets/logo.png';
import {Link} from 'react-router-dom';
import fb from '../../Assets/icons/fb.png';
import insta from '../../Assets/icons/insta.png';
import linkedin from '../../Assets/icons/linkedin.png';

export default function Footer(){
    return (
        <div className="footer">
            <div className="upDivFooter">
                <div className="leftDivFooter">
                    <div className="logoFooter">
                        <img src={logo} alt="Logo Grendy communication" />
                    </div>
                    <div className="nameAndAdressFooter">
                        <div className="grendyFooter"><span>Grendy </span>Communication</div>
                        <div className="agenceFooter">AGENCE WEB & COMMUNICATION</div>
                        <br/>
                        <p className="adresseFooter">8, rue du Vichoux</p>
                        <p className="adresseFooter">F-54920 Morfontaine</p>
                        <br/>
                        <a href="tel: +33355054911" className="phoneFooter">+33 (0) 3 55 05 49 11</a>
                    </div>
                </div>
                <div className="rightDivFooter">
                    <Link to="/" className="linkFooter">Mentions légales</Link>
                    <Link to="/" className="linkFooter">Conditions Générales de Vente (CGV)</Link>
                    <Link to="/contact" className="linkFooter">Contact</Link>
                </div>
            </div>

            <div className="downDivFooter">
                <img src={fb} alt="icone facebook grendy communication" className="iconSocialFooter"/>
                <img src={insta} alt="icone instagram grendy communication" className="iconSocialFooter"/>
                <img src={linkedin} alt="icone linkedIn grendy communication" className="iconSocialFooter"/>
            </div>
        </div>
    )
}